if (window.location.hostname !== "localhost") {
    let popUp = document.getElementById("cookiePopup");
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-K8HB26Q');";

    //When user clicks the accept button
    document.getElementById("acceptCookie").addEventListener("click", () => {
        //Create date object
        let d = new Date();
        //Increment the current time by 1 minute (cookie will expire after 1 minute)
        d.setMinutes(2 + d.getMinutes());
        //Create Cookie withname = myCookieName, value = thisIsMyCookie and expiry time=1 minute
        document.cookie = "marbinCookie=accepted; expires = " + d + ";";
        //Hide the popup
        popUp.classList.add("hide");
        popUp.classList.remove("show");
        window.location.reload();
    });

    //When user clicks the declines button
    document.getElementById("declineCookie").addEventListener("click", () => {
        //Create date object
        let d = new Date();
        //Increment the current time by 1 minute (cookie will expire after 1 minute)
        d.setMinutes(2 + d.getMinutes());
        //Create Cookie withname = myCookieName, value = thisIsMyCookie and expiry time=1 minute
        document.cookie = "marbinCookie=declined; expires = " + d + ";";
        //Hide the popup
        popUp.classList.add("hide");
        popUp.classList.remove("show");
        window.location.reload();
    });

    function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    //Check if cookie is already present
    const checkCookie = () => {
        //Read the cookie and split on "="
        let input = document.cookie.split("=");
        //Check for our cookie
        if (getCookie('marbinCookie') && getCookie('marbinCookie') == "accepted") {
            //Hide the popup
            popUp.classList.add("hide");
            popUp.classList.remove("show");
            document.head.appendChild(script);
        } else if (getCookie('marbinCookie') && getCookie('marbinCookie') == "declined") {
            //Hide the popup
            popUp.classList.add("hide");
            popUp.classList.remove("show");
        } else {
            //Show the popup
            popUp.classList.add("show");
            popUp.classList.remove("hide");
        }
    };

    //Check if cookie exists when page loads
    window.onload = () => {
        setTimeout(() => {
            checkCookie();
        }, 1000);
    };
} else {
    console.log("You are on localhost");
}